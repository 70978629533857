import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { GoNorthStar } from "react-icons/go";
import { GiBrain } from "react-icons/gi";
import { ImMagicWand } from "react-icons/im";

import "../themes/about.css";

const About = () => {
  const launchAboutPageAnimTablet = () => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".AboutPage", {
      clipPath: "circle(1200px at center center)",
      scrollTrigger: {
        trigger: ".AboutPage",
        scrub: true,
        start: "800px top",
        end: "2500px top",
      },
    });
    gsap.to(".AboutPage2", {
      clipPath: "circle(1200px at center center)",
      scrollTrigger: {
        trigger: ".AboutPage2",
        scrub: true,
        start: "1000px top",
        end: "2500px top",
      },
    });
    gsap.to(".AboutPageContainer", {
      filter: "blur(0px)",
      scrollTrigger: {
        trigger: ".AboutPageContainer",
        scrub: true,
        start: "800px top",
        end: "2500px top",
      },
    });
    gsap.to(".AboutText", {
      top: -700,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "1000px top",
        end: "+=3500",
      },
    });
    gsap.to(".textStar", {
      rotate: 360,
      scrollTrigger: {
        trigger: ".textStar",
        scrub: true,
        start: "1000px top",
        end: "+=3500",
      },
    });
    gsap.to(".spanBarAbout", {
      width: 100,
      scrollTrigger: {
        trigger: ".spanBarAbout",
        start: "1500px top",
        end: "+=3500",
        onLeaveBack: () => {
          gsap.to(".spanBarAbout", {
            width: 20,
          });
        },
        onEnterBack: () => {
          gsap.to(".spanBarAbout", {
            width: 100,
          });
        },
        onEnter: () => {
          gsap.to(".spanBarAbout", {
            width: 100,
          });
        },
      },
    });
    gsap.to(".AboutText .p span", {
      autoAlpha: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "1100px top",
        end: "+=1200",
      },
    });
    gsap.to(".AboutText2", {
      top: -500,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "2000px top",
        end: "+=4500",
      },
    });
    gsap.to(".textBrain", {
      scale: 1.2,
      scrollTrigger: {
        trigger: ".textBrain",
        scrub: true,
        start: "2000px top",
        end: "+=1500",
      },
    });
    gsap.to(".magicWand", {
      rotate: 90,
      scrollTrigger: {
        trigger: ".magicWand",
        start: "3000px top",
        end: "+=4500",
        onLeaveBack: () => {
          gsap.to(".magicWand", {
            rotate: 45,
          });
        },
        onEnter: () => {
          gsap.to(".magicWand", {
            rotate: 90,
          });
        },
      },
    });
    gsap.to(".AboutText2 p span", {
      autoAlpha: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "2000px top",
        end: "+=2100",
      },
    });
  };
  const launchAboutPageAnimDesktop = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".AboutPage", {
      clipPath: "circle(1200px at center center)",
      scrollTrigger: {
        trigger: ".AboutPage",
        scrub: true,
        start: "800px top",
        end: "2500px top",
      },
    });
    gsap.to(".AboutPage2", {
      clipPath: "circle(1200px at center center)",
      scrollTrigger: {
        trigger: ".AboutPage2",
        scrub: true,
        start: "1000px top",
        end: "2500px top",
      },
    });
    gsap.to(".AboutPageContainer", {
      filter: "blur(0px)",
      scrollTrigger: {
        trigger: ".AboutPageContainer",
        scrub: true,
        start: "800px top",
        end: "2500px top",
      },
    });
    gsap.to(".AboutText", {
      top: -600,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "1000px top",
        end: "+=3500",
      },
    });
    gsap.to(".textStar", {
      rotate: 360,
      scrollTrigger: {
        trigger: ".textStar",
        scrub: true,
        start: "1000px top",
        end: "+=3500",
      },
    });
    gsap.to(".spanBarAbout", {
      width: 100,
      scrollTrigger: {
        trigger: ".spanBarAbout",
        start: "1500px top",
        end: "+=3500",
        onLeaveBack: () => {
          gsap.to(".spanBarAbout", {
            width: 20,
          });
        },
        onEnterBack: () => {
          gsap.to(".spanBarAbout", {
            width: 100,
          });
        },
        onEnter: () => {
          gsap.to(".spanBarAbout", {
            width: 100,
          });
        },
      },
    });
    gsap.to(".AboutText .p span", {
      autoAlpha: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "1100px top",
        end: "+=1200",
      },
    });
    gsap.to(".AboutText2", {
      top: -500,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "2000px top",
        end: "+=4500",
      },
    });
    gsap.to(".textBrain", {
      scale: 1.8,
      scrollTrigger: {
        trigger: ".textBrain",
        scrub: true,
        start: "2000px top",
        end: "+=4500",
      },
    });
    gsap.to(".magicWand", {
      rotate: 90,
      scrollTrigger: {
        trigger: ".magicWand",
        start: "3000px top",
        end: "+=4500",
        onLeaveBack: () => {
          gsap.to(".magicWand", {
            rotate: 45,
          });
        },
        onEnter: () => {
          gsap.to(".magicWand", {
            rotate: 90,
          });
        },
      },
    });
    gsap.to(".AboutText2 p span", {
      autoAlpha: 1,
      stagger: 0.1,
      scrollTrigger: {
        trigger: ".AboutText",
        scrub: true,
        start: "2000px top",
        end: "+=2100",
      },
    });
  };

  useEffect(() => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      launchAboutPageAnimTablet();
    } else {
      launchAboutPageAnimDesktop();
    }
  }, []);

  return (
    <div>
      <div className="AboutPageContainer">
        <div className="AboutPage"></div>
        <div className="AboutPage2"></div>
      </div>
      <div className="AboutText">
        <div className="p">
          <span className="opacity-5">Web</span>{" "}
          <span className="opacity-5">et</span>{" "}
          <span className="opacity-5">Mobile</span>{" "}
          <span className="opacity-5">Fullstack.</span>{" "}
          <span className="opacity-5">Français</span>{" "}
          <span className="opacity-5">22</span>{" "}
          <span className="opacity-5">ans.</span>{" "}
          <span className="opacity-5 inline-block textStar">
            <GoNorthStar color="#f1f1f1" className="goNorthStar" />
          </span>{" "}
          <span className="opacity-5">Diplomé</span>{" "}
          <span className="opacity-5"> RNCP 5</span>{" "}
          <span className="opacity-5">Développeur</span>{" "}
          <span className="opacity-5">web</span>{" "}
          <span className="opacity-5">fullstack</span>
          {"   "}
          <span className="opacity-5 inline-block -translate-y-3">
            <div className="spanBarAbout"></div>
          </span>
          {"   "}
          <span className="opacity-5">En</span>{" "}
          <span className="opacity-5">Formation</span>{" "}
          <span className="opacity-5">RNCP 6</span>{" "}
          <span className="opacity-5">Concepteur</span>{" "}
          <span className="opacity-5">développeur</span>{" "}
          <span className="opacity-5">fullstack.</span>{" "}
        </div>
      </div>
      <div className="AboutText2">
        <p>
          <span className="opacity-5">Deux</span>{" "}
          <span className="opacity-5">ans</span>{" "}
          <span className="opacity-5">d'expérience</span>{" "}
          <span className="opacity-5">profesionnelle</span>{" "}
          <span className="opacity-5">poste</span>{" "}
          <span className="opacity-5">développeur</span>{" "}
          <span className="opacity-5">web</span>{" "}
          <span className="opacity-5">fullstack</span>{" "}
          <span className="opacity-5 inline-block">
            <GiBrain
              color="#f1f1f1"
              size={50}
              className="scale-[0.4] textBrain"
            />
          </span>{" "}
          <span className="opacity-5">Vingtaine</span>{" "}
          <span className="opacity-5">de</span>{" "}
          <span className="opacity-5">projets</span>{" "}
          <span className="opacity-5">lancés.</span>{" "}
          <span className="opacity-5">Travaux</span>{" "}
          <span className="opacity-5">Créatifs</span>{" "}
          <span className="opacity-5">et</span>{" "}
          <span className="opacity-5">Efficace.</span>{" "}
          <span className="opacity-5 inline-block">
            <ImMagicWand color="#f1f1f1" className="rotate-45 magicWand" />
          </span>{" "}
          <span className="opacity-5">Modernité</span>{" "}
          <span className="opacity-5">et</span>{" "}
          <span className="opacity-5">Performance.</span>{" "}
          <span className="opacity-5">Voyage</span>{" "}
          <span className="opacity-5">et</span>{" "}
          <span className="opacity-5">Sport.</span>{" "}
        </p>
      </div>
    </div>
  );
};

export default About;
